import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  cardGridItem: {
    zIndex: 10,
    marginBottom: '48px',
  },
}))
const CardGridItem = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      zeroMinWidth
      className={`${classes.cardGridItem} rise-transition-item`}
    >
      {children}
    </Grid>
  )
}

CardGridItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
}

export default CardGridItem
