import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { PropTypes } from 'prop-types'
import {
  Container,
  Box,
  makeStyles,
  withTheme,
  Typography,
  Hidden,
  Select,
  MenuItem,
} from '@material-ui/core'
import { TransitionState } from 'gatsby-plugin-transition-link'
import CloseIcon from '@material-ui/icons/Close'
import Layout from '../../components/Layout'

import NewsletterBlock from '../../components/NewsletterBlock'
import AboutBlock from '../../components/AboutBlock'
import { TYPES } from '../../utils/constants'
import CardGrid from '../../components/CardGrid'
import ContentCard from '../../components/ContentCard'
import BaseReadIcon from '../../components/graphics/BaseReadIcon'
import BaseWatchIcon from '../../components/graphics/BaseWatchIcon'
import BaseListenIcon from '../../components/graphics/BaseListenIcon'
import replaceQueryParam from '../../utils/replaceQueryParam'
import { capitalize } from '../../utils/string'

const useStyles = makeStyles((theme) => ({
  main: {
    transition: 'all 0.45s ease',
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  entering: {
    opacity: 0,
    transform: 'translate(0px,20px)',
  },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  active: {},
  topic: {
    transition: 'color 0.25s ease',
    cursor: 'pointer',
    color: theme.palette.primary.grey,
    '&$active': { color: theme.palette.primary.light, fontWeight: 'bold' },
  },
  select: {
    paddingLeft: 0,
    whiteSpace: 'normal',
    width: '46%',
    '&:before': {
      borderColor: theme.palette.primary.light,
    },
    '&:after': {
      borderColor: theme.palette.primary.light,
    },
    '& >div': {
      paddingLeft: 0,
    },
  },
  icon: {
    fill: theme.palette.primary.light,
  },
  largeIcon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 38,
    },
  },
  close: {
    opacity: 1,
    transition: 'opacity 0.3s ease',
    cursor: 'pointer',
    transform: 'translateY(-30%)',
    '&:hover': {
      opacity: 0.7,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: '8px',
      right: '12px',
      transform: 'none',
    },
  },
}))

const BaseBrowseAllTemplate = ({
  episodes, series, theme, lastPath, publishers, tags,
}) => {
  const [activeType, setActiveType] = useState('all')
  const [activeTopic, setActiveTopic] = useState('all')
  const classes = useStyles()
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const initialTag = urlParams.get('tag')
    if (initialTag) {
      setActiveTopic(initialTag)
    }
    const initialMedia = urlParams.get('media')
    if (initialMedia) {
      setActiveType(initialMedia)
    }
  }, [])
  const newReleases = episodes.edges.map((episode) => {
    const updatedEpisode = { ...episode }
    const matchedSeries = series.edges.filter(
      (seriesItem) => seriesItem.node.frontmatter.content.indexOf(
        episode.node.frontmatter.id,
      ) >= 0,
    )
    const matchingSeriesTitle = matchedSeries.length >= 1 ? matchedSeries[0].node.frontmatter.title : ''
    updatedEpisode.node.fields.seriesTitle = matchingSeriesTitle
    updatedEpisode.node.fields.seriesTitleSlug = matchingSeriesTitle.length > 0 ? matchedSeries[0].node.fields.slug : ''
    return updatedEpisode
  })
  const types = ['watch', 'listen', 'read']
  const themeMap = {
    watch: { color: theme.palette.primary.secondary, icon: BaseWatchIcon },
    listen: { color: theme.palette.primary.main, icon: BaseListenIcon },
    read: { color: theme.palette.primary.tertiary, icon: BaseReadIcon },
  }
  const filteredTypeCards = newReleases
    .filter(
      ({ node: { frontmatter } }) => activeType === 'all'
        || TYPES[frontmatter.templateKey] === activeType.toLowerCase(),
    )

  const customTags = []
  tags.edges.forEach(({ node }) => {
    const obj = {}
    obj.label = node.frontmatter.label
    obj.id = node.frontmatter.id
    customTags.push(obj)
  })

  const customPublishers = []
  publishers.edges.forEach(({ node }) => {
    const obj = {}
    obj.label = node.frontmatter.label
    obj.id = node.frontmatter.id
    customPublishers.push(obj)
  })


  const availableTags = customTags.filter((tag) => filteredTypeCards
    .filter(({ node: { frontmatter } }) => frontmatter.tags.indexOf(tag.id) !== -1).length > 0)


  const availablePublishers = customPublishers.filter((publisher) => filteredTypeCards
    .filter(({ node: { frontmatter } }) => frontmatter.publisher === publisher.id).length > 0)


  const availableTagsWithPublishers = [...availableTags, ...availablePublishers]
  const availableTagsMap = availableTagsWithPublishers.map((tag) => <Box key={tag.id} onClick={() => { setActiveTopic(tag.id); replaceQueryParam('tag', tag.id) }} mb={2.5} className={`${classes.topic} ${tag.id === activeTopic ? classes.active : ''}`}><Typography>{tag.label}</Typography></Box>)

  const filteredTypeTopicCards = filteredTypeCards
    .filter(({ node: { frontmatter } }) => activeTopic === 'all'
      || frontmatter.tags.indexOf(activeTopic) !== -1 || frontmatter.publisher === activeTopic)
  const filteredCardsMap = filteredTypeTopicCards.map(({ node: { frontmatter, fields } }) => (
    <ContentCard
      key={frontmatter.id}
      target={frontmatter.externalUrl ? '_blank' : '_self'}
      to={frontmatter.externalUrl ? frontmatter.externalUrl : fields.slug}
      thumbnail={frontmatter.heroImg ? frontmatter.heroImg : frontmatter.thumbnail}
      type={TYPES[frontmatter.templateKey]}
      title={`${fields.autoEpisodeNumber ? `${fields.autoEpisodeNumber.toString().padStart(2, '0')}. ` : ''}${frontmatter.title}`}
      mediaDuration={frontmatter.mediaDuration}
      subtitle={fields.seriesTitle}
      toSecondary={fields.seriesTitleSlug}
    />
  ))
  return (
    <Container overflow="hidden" maxWidth="xl">
      <Box mb={8} mt={5} display="flex">
        <Hidden smDown>
          <Box width="240px">
            <Box onClick={() => { setActiveTopic('all'); replaceQueryParam('tag', 'all') }} mb={2.5} className={`${classes.topic} ${activeTopic === 'all' ? classes.active : ''}`}><Typography>All Tags</Typography></Box>
            {availableTagsMap}
          </Box>
        </Hidden>
        <Box width="100%">
          <Box display="flex" justifyContent={{ md: 'space-between' }} pl={3} pr={{ xs: 3, sm: 0 }} mb={3}>

            <Hidden mdUp>
              <Box display="flex" margin="0 auto" maxWidth="320px" justifyContent="space-between" width="100%" mb={3}>
                <Select
                  value={activeTopic}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  renderValue={(topic) => {
                    if (topic === 'all') { return <Box px={0.5} mr={1}><Typography>All Tags</Typography></Box> }
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: 'pointer', whiteSpace: 'normal' }}
                      >
                        <Box ml={1} mr={1}>
                          <Typography>
                            {availableTagsWithPublishers
                              .find((tag) => tag.id === topic).label}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  }}
                >
                  <MenuItem value="all">
                    <Box
                      onClick={() => {
                        setActiveTopic('all')
                        replaceQueryParam('tag', 'all')
                      }}
                      display="flex"
                      alignItems="center"
                      height="24px"
                      style={{ cursor: 'pointer' }}
                    >
                      <Box px={0.5} my={1}><Typography>All Tags</Typography></Box>
                    </Box>
                  </MenuItem>
                  {availableTagsWithPublishers.map((tag) => (
                    <MenuItem
                      value={tag.id}
                      key={tag.id}

                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        my={1}
                        onClick={() => {
                          setActiveTopic(tag.id)
                          replaceQueryParam('tag', tag.id)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Typography>{tag.label}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>

                <Select
                  value={activeType}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  renderValue={(type) => {
                    if (type === 'all') { return <Box px={0.5} mr={1}><Typography>All Media</Typography></Box> }
                    const Ctor = themeMap[type].icon
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: 'pointer', whiteSpace: 'normal' }}
                        mr={1}
                      >
                        <Ctor
                          fill={
                        activeType === type
                          ? theme.palette.primary.light
                          : theme.palette.primary.grey
                      }
                        />
                        <Box ml={1}><Typography>{capitalize(type)}</Typography></Box>
                      </Box>
                    )
                  }}
                >
                  <MenuItem value="all">
                    <Box
                      onClick={() => {
                        setActiveType('all')
                        replaceQueryParam('media', 'all')
                      }}
                      display="flex"
                      alignItems="center"
                      height="24px"
                      style={{ cursor: 'pointer' }}
                    >
                      <Box px={0.5} my={1}><Typography>All Media</Typography></Box>
                    </Box>
                  </MenuItem>
                  {types.map((type) => {
                    const Ctor = themeMap[type].icon
                    return (
                      <MenuItem value={type} key={type}>
                        <Box
                          onClick={() => {
                            setActiveType(type)
                            setActiveTopic('all')
                            replaceQueryParam('tag', 'all')
                            replaceQueryParam('media', type)
                          }}
                          display="flex"
                          alignItems="center"
                          my={1}
                          style={{ cursor: 'pointer' }}
                        >
                          <Ctor
                            fill={
                        activeType === type
                          ? themeMap[type].color
                          : theme.palette.primary.grey
                      }
                          />
                          <Box ml={1}><Typography>{capitalize(type)}</Typography></Box>
                        </Box>

                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            </Hidden>
            <Hidden smDown>
              <Box display="flex">
                <Box mr={3}>
                  <Box
                    onClick={() => {
                      setActiveType('all')
                      replaceQueryParam('media', 'all')
                    }}
                    display="flex"
                    alignItems="center"
                    height="24px"
                    mb={1.5}
                    style={{ cursor: 'pointer' }}
                  >
                    <Box px={0.5}><Typography>All Media</Typography></Box>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      height: '4px',
                      borderRadius: '4px',
                      background: theme.palette.primary.light,
                      transition: 'transform 0.28s ease',
                      transform: `scaleX(${activeType === 'all' ? 1 : 0})`,
                    }}
                  />
                </Box>
                {types.map((type) => {
                  const Ctor = themeMap[type].icon
                  return (
                    <Box mr={3} key={type}>
                      <Box
                        onClick={() => {
                          setActiveType(type)
                          replaceQueryParam('media', type)
                        }}
                        display="flex"
                        alignItems="center"
                        mb={1.5}
                        style={{ cursor: 'pointer' }}
                      >
                        <Ctor
                          fill={
                        activeType === type
                          ? themeMap[type].color
                          : theme.palette.primary.grey
                      }
                        />
                        <Box ml={1}><Typography>{capitalize(type)}</Typography></Box>
                      </Box>
                      <Box
                        style={{
                          width: '100%',
                          height: '4px',
                          borderRadius: '4px',
                          background: themeMap[type].color,
                          transition: 'transform 0.28s ease',
                          transform: `scaleX(${activeType === type ? 1 : 0})`,
                        }}
                      />
                    </Box>
                  )
                })}
              </Box>
            </Hidden>

            <Box display="flex" className={classes.close} onClick={() => (lastPath ? navigate(lastPath) : navigate('/'))}>
              <CloseIcon className={classes.largeIcon} />
              <Hidden xsDown>
                <Box ml={1}><Typography>Close</Typography></Box>
              </Hidden>
            </Box>
          </Box>
          {filteredCardsMap.length > 0 ? (
            <CardGrid title="">{filteredCardsMap}</CardGrid>
          ) : (
            <Box display="flex" px={3} mt={5} mb={8}>
              {' '}
              <Typography variant="h3">No Results</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  )
}
const BrowseAllTemplate = withTheme(BaseBrowseAllTemplate)
BaseBrowseAllTemplate.propTypes = {
  episodes: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({
            heroImg: PropTypes.shape({}),
          }),
        }),
      }),
    ),
  }).isRequired,
  series: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({}),
        }),
      }),
    ),
  }).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        grey: PropTypes.string,
        light: PropTypes.string,
        main: PropTypes.string,
        secondary: PropTypes.string,
        tertiary: PropTypes.string,
      }),
    }),
  }).isRequired,
  publishers: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({}),
        }),
      }),
    ),
  }).isRequired,
  tags: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({}),
        }),
      }),
    ),
  }).isRequired,
  lastPath: PropTypes.string,
}

BaseBrowseAllTemplate.defaultProps = {
  lastPath: '',
}

const BrowseAllPage = ({
  data: {
    episodes, series, publishers, tags,
  },
}) => {
  const classes = useStyles()

  useEffect(() => {
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'WebPage',
        breadcrumb: 'Home > Browse All',
        id: 'browse-all',
        name: 'Browse All Page',
        page_category: 'Browse All',
      },
    })
  }, [])

  return (
    <Layout>
      <TransitionState>
        {({ transitionStatus, entry }) => (
          <Box className={`${classes.main} ${classes[transitionStatus]}`}>
            <BrowseAllTemplate
              series={series}
              episodes={episodes}
              publishers={publishers}
              tags={tags}
              lastPath={entry.state.location}
            />
            <AboutBlock />
            <NewsletterBlock />
          </Box>
        )}
      </TransitionState>
    </Layout>
  )
}

BrowseAllPage.propTypes = {
  data: PropTypes.shape({
    episodes: PropTypes.shape({}).isRequired,
    series: PropTypes.shape({}).isRequired,
    publishers: PropTypes.shape({}).isRequired,
    tags: PropTypes.shape({}).isRequired,
  }).isRequired,
}

export const pagesQuery = graphql`
  query browseAll {
    series: allMarkdownRemark(
      filter: {
        fields: {
          slug: { regex: "//series//" }
          content: { elemMatch: { fields: { slug: { regex: "//p//" } } } }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            content
          }
          fields {
            slug
          }
        }
      }
    }
    episodes: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { in: ["Audio", "Video", "MixedMedia"] }
          hidePost : {ne : true}
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
            autoEpisodeNumber
          }
          frontmatter {
            id
            title
            templateKey
            content
            description
            mediaDuration
            publisher
            tags
            externalUrl
            heroImg {
              childImageSharp {
                fluid(maxWidth: 686, maxHeight: 386) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    publishers: allMarkdownRemark(
      filter:{
        fields:{
          slug: {
            regex: "//publishers//"
          }
        }
      }
    ){
      edges{
        node{
          frontmatter{
            id
            label
            image
          }
        }
      }
    }
    tags: allMarkdownRemark(
      filter:{
        fields:{
          slug: { regex:"//tags//"}
        }
      }
    ){
      edges{
        node{
          frontmatter{
            label
            id
          }
        }
      }
    }
  }
`

export { BrowseAllTemplate }
export default BrowseAllPage
