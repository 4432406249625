import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const BaseWatchIcon = ({ fill }) => (
  <>
    <Hidden xsUp>
      <p>Listen</p>
    </Hidden>
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3488 12.7373C22.3488 10.0056 21.2604 7.38581 19.323 5.45422C17.3857 3.52263 14.758 2.43747 12.0182 2.43747C9.2783 2.43747 6.65066 3.52263 4.71328 5.45422C2.77591 7.38581 1.6875 10.0056 1.6875 12.7373V20.9772C1.6875 21.1593 1.76006 21.3339 1.88922 21.4627C2.01838 21.5915 2.19355 21.6638 2.37621 21.6638H5.81977C6.00242 21.6638 6.1776 21.5915 6.30676 21.4627C6.43592 21.3339 6.50848 21.1593 6.50848 20.9772V14.1106C6.50848 13.9285 6.43592 13.7538 6.30676 13.6251C6.1776 13.4963 6.00242 13.424 5.81977 13.424H3.06492V12.7373C3.06492 10.3698 4.00821 8.09934 5.68727 6.4253C7.36632 4.75125 9.64362 3.81078 12.0182 3.81078C14.3927 3.81078 16.67 4.75125 18.3491 6.4253C20.0281 8.09934 20.9714 10.3698 20.9714 12.7373V13.424H18.2166C18.0339 13.424 17.8587 13.4963 17.7296 13.6251C17.6004 13.7538 17.5279 13.9285 17.5279 14.1106V20.9772C17.5279 21.1593 17.6004 21.3339 17.7296 21.4627C17.8587 21.5915 18.0339 21.6638 18.2166 21.6638H21.6601C21.8428 21.6638 22.018 21.5915 22.1471 21.4627C22.2763 21.3339 22.3488 21.1593 22.3488 20.9772V12.7373ZM5.13105 14.7973V20.2905H3.06492V14.7973H5.13105ZM18.9053 20.2905V14.7973H20.9714V20.2905H18.9053Z"
        fill={fill}
        style={{ transition: 'fill 0.25s ease' }}
      />
    </SvgIcon>
  </>
)

BaseWatchIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default BaseWatchIcon
