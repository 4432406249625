import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const BaseWatchIcon = ({ fill }) => (
  <>
    <Hidden xsUp>
      <p>Watch</p>
    </Hidden>
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9693 3H2.03084C1.53238 3 1.20007 3.32727 1.20007 3.81818V20.1818C1.20007 20.6727 1.53238 21 2.03084 21H21.9693C22.4678 21 22.8001 20.6727 22.8001 20.1818V3.81818C22.8001 3.32727 22.4678 3 21.9693 3ZM2.86161 19.3636V4.63636H21.1385V19.3636H2.86161Z"
        style={{ transition: 'fill 0.25s ease' }}
        fill={fill}
      />
      <path
        d="M15.7385 11.2636L9.9231 7.99085C9.75694 7.90903 9.67386 7.90903 9.50771 7.90903C9.00925 7.90903 8.67694 8.2363 8.67694 8.72721V15.2727C8.67694 15.7636 9.00925 16.0908 9.50771 16.0908C9.67386 16.0908 9.75694 16.0908 9.9231 16.009L15.7385 12.7363C15.9046 12.6545 15.9877 12.5727 16.0708 12.409C16.2369 11.9999 16.1539 11.509 15.7385 11.2636ZM10.3385 13.8818V10.1181L13.6616 11.9999L10.3385 13.8818Z"
        style={{ transition: 'fill 0.25s ease' }}
        fill={fill}
      />
    </SvgIcon>
  </>
)

BaseWatchIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default BaseWatchIcon
