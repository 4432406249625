import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const BaseReadIcon = ({ fill }) => (
  <>
    <Hidden xsUp>
      <p>Read</p>
    </Hidden>
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.39996"
        y="3.60004"
        width="19.2"
        height="1.8"
        rx="0.9"
        fill={fill}
        style={{ transition: 'all 0.25s ease' }}
      />
      <rect
        x="2.39996"
        y="8.40005"
        width="19.2"
        height="1.8"
        rx="0.9"
        fill={fill}
        style={{ transition: 'all 0.25s ease' }}
      />
      <rect
        x="2.39996"
        y="13.2001"
        width="19.2"
        height="1.8"
        rx="0.9"
        fill={fill}
        style={{ transition: 'all 0.25s ease' }}
      />
      <rect
        x="2.39996"
        y="18"
        width="15"
        height="1.8"
        rx="0.9"
        fill={fill}
        style={{ transition: 'all 0.2s ease' }}
      />
    </SvgIcon>
  </>
)

BaseReadIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default BaseReadIcon
