import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid, Box, Typography, Container,
} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import IntersectBox from './IntersectBox'
import CardGridItem from './CardGridItem'

const chunk = (arr, size) => Array
  .from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))

const CardGrid = ({
  title, titleMb, children, width, titleVariant,
}) => {
  let rows = null
  switch (width) {
    case 'xs':
    case 'ss':
    case 'sm':
      rows = chunk(children, 1).map((section) => (
        <IntersectBox key={section[0].key} type="riseTrigger">
          <Grid container spacing={3}>
            {section.map((item) => (
              <CardGridItem key={item.key}>{item}</CardGridItem>
            ))}
          </Grid>
        </IntersectBox>
      ))
      break
    case 'md':
      rows = chunk(children, 2).map((section) => (
        <IntersectBox key={section[0].key} type="riseTrigger">
          <Grid container spacing={3}>
            {section.map((item) => (
              <CardGridItem key={item.key}>{item}</CardGridItem>
            ))}
          </Grid>
        </IntersectBox>
      ))
      break
    default:
      rows = chunk(children, 3).map((section) => (
        <IntersectBox key={section[0].key} type="riseTrigger">
          <Grid container spacing={3}>
            {section.map((item) => (
              <CardGridItem key={item.key}>{item}</CardGridItem>
            ))}
          </Grid>
        </IntersectBox>
      ))
  }

  return (
    <Container mb={8} maxWidth="xl">
      {title ? (
        <IntersectBox type="riseTrigger" rootMargin="-40px">
          <Box
            mb={titleMb}
            display="flex"
            justifyContent="center"
            className="rise-transition-item"
          >
            <Typography variant={titleVariant}>{title}</Typography>
          </Box>
        </IntersectBox>
      ) : null}

      {rows}
    </Container>
  )
}

CardGrid.propTypes = {
  title: PropTypes.string,
  titleMb: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  titleVariant: PropTypes.string,
  width: PropTypes.string.isRequired,
}

CardGrid.defaultProps = {
  title: null,
  titleVariant: 'h2',
  titleMb: 6,
}

export default withWidth()(CardGrid)
