import React, { useState } from 'react'
import {
  Box, Typography, makeStyles, withTheme,
} from '@material-ui/core'
import TransitionLink from 'gatsby-plugin-transition-link'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import Thumbnail from './Thumbnail'
import EyebrowWithType from './EyebrowWithType'
import MediaDuration from './MediaDuration'
import HiddenText from './HiddenText'

import { withEllipsis } from '../utils/string'
import seriesTitleSlug from '../utils/seriesTitleSlug'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    maxWidth: '295px',
    margin: '0 auto',
  },
  listen: {
    color: theme.palette.primary.main,
  },
  watch: {
    color: theme.palette.primary.secondary,
  },
  read: {
    color: theme.palette.primary.tertiary,
  },
  listenBorder: {
    borderColor: theme.palette.primary.main,
  },
  watchBorder: {
    borderColor: theme.palette.primary.secondary,
  },
  readBorder: {
    borderColor: theme.palette.primary.tertiary,
  },
  subtitle: {
    '&:hover $subtitleHighlight': {
      transform: 'scaleX(1)',
    },
  },
  subtitleHighlight: {
    height: '2px',
    width: '100%',
    transition: 'transform 0.25s ease',
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    background: theme.palette.secondary.tertiary,
  },
}))


const ContentCard = ({
  active,
  thumbnail,
  episodeNumber,
  type,
  eyebrow,
  title,
  description,
  descLimit,
  subtitle,
  mediaDuration,
  to,
  toSecondary,
  theme,
  flipTitle,
  hoverEffect,
  target = '_self',
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const classes = useStyles()
  const Ctor = to.length > 0 ? TransitionLink : Box
  const CtorSecondary = toSecondary.length > 0 ? TransitionLink : Box
  const imageLinkId = uuid()
  const toSecondaryCleaned = toSecondary ? `/series?title=${seriesTitleSlug(toSecondary)}` : ''

  return (
    <Box
      style={{ color: '#ffffff', textDecoration: 'none' }}
      data-media-name={title}
    >
      <Box
        className={[classes.card, classes[`${type}Border`]].join(' ')}
        style={
          active
            ? {
              borderWidth: 4,
              borderRadius: 40,
              borderStyle: 'solid',
              padding: 12,
            }
            : {}
        }
      >
        <Ctor
          aria-labelledby={imageLinkId}
          target={target}
          to={to}
          exit={{
            length: 0.4,
          }}
          entry={{
            delay: 0.4,
            length: 0.9,
          }}
          onMouseEnter={hoverEffect ? () => (active ? null : setIsHovered(true)) : null}
          onMouseLeave={hoverEffect ? () => (active ? null : setIsHovered(false)) : null}
        >
          <HiddenText id={imageLinkId}>{title}</HiddenText>
          <Thumbnail image={thumbnail} type={type} active={isHovered} />
        </Ctor>
        <Box px={active ? 1 : 0} mt={4}>
          <Box display="flex" alignItems="center" flexDirection="row" mb={1.5}>
            {type && eyebrow ? <EyebrowWithType type={type} /> : null}
            {mediaDuration ? <MediaDuration value={mediaDuration} /> : null}
          </Box>
          <Box
            display="flex"
            flexDirection={flipTitle ? 'column-reverse' : 'column'}
          >
            {subtitle ? (
              <Box>
                <CtorSecondary
                  to={toSecondaryCleaned}
                  exit={{
                    length: 0.4,
                  }}
                  entry={{
                    delay: 0.4,
                    length: 0.9,
                  }}
                  style={{ display: 'inline-block' }}
                  target={target}
                >
                  <Box my={{ xs: 0.5, md: 1 }} display="inline-block" className={classes.subtitle}>
                    <Typography
                      variant="h6"
                      component="p"
                      style={{
                        color: theme.palette.secondary.tertiary,
                        fontWeight: 'bold',
                      }}
                    >
                      {subtitle}
                    </Typography>
                    <Box className={toSecondaryCleaned.length > 0 ? classes.subtitleHighlight : ''} />
                  </Box>
                </CtorSecondary>
              </Box>
            ) : null}
            <Ctor
              to={to}
              exit={{
                length: 0.4,
              }}
              entry={{
                delay: 0.4,
                length: 0.9,
              }}
              style={{ color: '#ffffff', textDecoration: 'none' }}
              onMouseEnter={hoverEffect ? () => (active ? null : setIsHovered(true)) : null}
              onMouseLeave={hoverEffect ? () => (active ? null : setIsHovered(false)) : null}
              target={target}
            >
              <Typography
                className={active || isHovered ? classes[type] : ''}
                variant="h6"
                component="p"
                style={{ lineHeight: '150%', transition: 'color 0.2s ease' }}
              >
                {episodeNumber ? `${episodeNumber.toString().padStart(2, '0')}. ` : ''}
                {title}
              </Typography>
            </Ctor>
          </Box>
          {description ? (
            <Box mt={2}>
              <Typography
                variant="body1"
                style={{
                  color: isHovered
                    ? theme.palette.primary.light
                    : theme.palette.primary.grey,
                  lineHeight: '175%',
                  transition: 'color 0.2s ease',
                  maxWidth: '340px',
                }}
              >
                {withEllipsis(description, descLimit)}
              </Typography>
            </Box>
          ) : null}
          {active ? (
            <Box mt={2} fontWeight="bold">
              <Typography
                variant="body1"
                style={{ fontSize: 16, lineHeight: '150%' }}
              >
                {`This ${type === 'read' ? 'Post' : 'Episode'}`}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

ContentCard.propTypes = {
  thumbnail: PropTypes.shape({}).isRequired,
  type: PropTypes.oneOf(['listen', 'read', 'watch']),
  title: PropTypes.string.isRequired,
  episodeNumber: PropTypes.number,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  mediaDuration: PropTypes.number,
  target: PropTypes.string,
  to: PropTypes.string,
  toSecondary: PropTypes.string,
  eyebrow: PropTypes.bool,
  active: PropTypes.bool,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        grey: PropTypes.string,
        light: PropTypes.string,
      }),
      secondary: PropTypes.shape({
        tertiary: PropTypes.string,
      }),
    }),
  }).isRequired,
  flipTitle: PropTypes.bool,
  hoverEffect: PropTypes.bool,
  descLimit: PropTypes.number,
}

ContentCard.defaultProps = {
  subtitle: null,
  type: null,
  description: null,
  episodeNumber: null,
  mediaDuration: null,
  eyebrow: true,
  hoverEffect: true,
  active: false,
  flipTitle: false,
  descLimit: 80,
  toSecondary: '',
  to: '',
  target: '_self',
}

export default withTheme(ContentCard)
