import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, Box,
} from '@material-ui/core'


const MediaDuration = ({
  value,
}) => (
  <Box ml={0.75}>
    <Typography variant="body1">
      |
      {' '}
      {value}
      {' '}
      min
    </Typography>
  </Box>
)

MediaDuration.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

export default MediaDuration
